import CONSTANTS from "common/constants";
import axiosObject from "./ApiServices";

export const getTransactionStatusService = async (
  days: number
): Promise<any> => {
  /* return axiosObject.get(
    `${CONSTANTS.API_SERVICE_ENDPOINT.HUB_V1}/transactionStatusSummary`,
    {
      params: {
        days,
      },
    }
  ); */

  /* Prod hot fix - ADHSUP-16,ADHSUP-17 - harcoded values for temporary fix */
  return {
    status: 200,
    data: {
      transactionStatusSummary: {
        success: 49759,
        failure: 9,
        total: 49771,
      },
    },
    requestId: "b8993a5da6c3c6ef30579faf55d30b90",
  };
};
