import CONSTANTS from "common/constants";
import axiosAccount from "./ApiServices";

export const TransactionalLogList = async (paramsData: any): Promise<any> => {
  return axiosAccount?.get(
    `${CONSTANTS.API_SERVICE_ENDPOINT.HUB_V1}/transactionLogs`,
    {
      params: {
        ...paramsData,
      },
    }
  );
};

export const TransactionalLogSummary = async (
  paramsData: any
): Promise<any> => {
  /* return axiosAccount.get(
    `${CONSTANTS.API_SERVICE_ENDPOINT.HUB_V1}/transactionLogChannelSummary`,
    {
      params: {
        days: paramsData,
      },
    }
  ); */

  /* Prod hot fix - ADHSUP-16,ADHSUP-17 - harcoded values for temporary fix */
  return {
    status: 200,
    data: {
      transactionLogChannelSummary: [
        {
          date: "2025-03-19T00:00:00Z",
          nats: 42581,
          api: 529,
          sqs: 24,
        },
        {
          date: "2025-03-20T00:00:00Z",
          nats: 6494,
          api: 114,
          sqs: 0,
        },
      ],
    },
    requestId: "ef9ec35ee22cee625cec334818fbd739",
  };
};

export const TransactionalLogDetails = async (
  paramsData: any
): Promise<any> => {
  return axiosAccount.get(
    `${CONSTANTS.API_SERVICE_ENDPOINT.HUB_V1}/transactionLogs/${paramsData}`
  );
};

export const TransactionalLogFilter = async (
  paramsData: string
): Promise<any> => {
  return axiosAccount.get(
    `${CONSTANTS.API_SERVICE_ENDPOINT.HUB_V1}/transactionLogs/filters`,
    {
      params: {
        columnData: paramsData,
      },
    }
  );
};
